<!-- 真人视讯 -->
<template>
  <div class="content">
    <div class="mod-sports">
      <div class="mod-banner" style="height: 460px">
        <!--焦点图-->
        <Banner />
        <!--end 焦点图-->
      </div>
      <ul class="sport-platform">
        <li class="betb2b-item" v-for="sport in lists" :key="sport.id"   @click="_getToken(sport, 2)">
          <img :src="systemImgHost + sport.imgUrl" />
          <div class="btn"></div>
        </li>
      </ul>

      <div class="sport-text page-text">
        <!-- Sports Lobby within <div class="sport-text page-text"> -->
        <div class="layout" :class="{ 'layout-height': moreShow }">
          <h1>
            FUN88 India: Play and win jackpot on the best sports betting site
          </h1>
          <p>
            It’s time for your passion to pay back with Fun88 –
            <b style="color: #00a6ff"
              >one of the top sports betting sites in India.</b
            >
          </p>
          <p>
            Place a live bet or try your luck at 10,000+
            <b style="color: #00a6ff">different games</b> as we make your foray
            into this exciting arena more thrilling with personalized bonuses
            and promotions!
          </p>
          <p>
            Whether you’re a cricket follower or an ardent fan, we offer you the
            best way to put your love for the game to the test with multiple
            betting options and a variety of bet types.
          </p>
          <p>
            With pre-match betting and live-match-betting we make cricket
            gambling simpler, easier and more exciting than ever before. From
            the pitch to the toss to the top match scorer to the winning side,
            every nuance of the cricketing game is available for you to punt on.
            And, not to miss our fast settlement process and seamless payouts!
          </p>
          <h2>Top sportsbook site on the go!</h2>
          <p>
            Playing online betting games has never been easier than right now,
            and right here at Fun88. You can easily access it on your mobile
            phone or laptop as you enjoy the game’s live stream on the
            <b>best sports betting site</b>.
          </p>
          <p>
            You can place your bets on international cricketing from across the
            world, encompassing all formats of the game – be it T20 (IPL, CPL),
            T10, test cricket and other premier leagues. With in-play markets,
            competitive rates (odds), tips and analysis, you’re sure to
            experience the best of sporting events around the world. We also
            bring our support on the ground as we partner with the Caribbean
            Premier League 2020 as their official sports betting partner. We
            also give you a detailed glance of match results, current game odds,
            bonuses, and other promotions, making it easy to view from any part
            of the world. That being said, there are plenty of other games to
            bet on in India, including Basketball, Football, Badminton, Tennis,
            Rugby, Kabaddi and many more.
          </p>
          <h2>Types of sports betting in india</h2>
          <div class="row types-of-sports-row">
            <div class="col col-lg">
              <img
                src="../../assets/images/in/BETTING.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
              >
                PRE-MATCH BETTING
              </div>
              <p style="text-align: center; padding: 1em">
                If you’re placing your bets before a match starts, then it will
                fall in the category of “Pre-match Betting.” At Fun88, you can
                find multiple options to place your bets on all kinds of sports,
                like cricket, football, tennis, basketball, volleyball, kabaddi
                much before a match starts. You can easily visit the website and
                place your pre-match bets.
              </p>
            </div>
            <div class="col col-lg">
              <img
                src="../../assets/images/in/LIVEBETTING.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
              >
                LIVE BETTING
              </div>
              <p style="text-align: center; padding: 1em">
                In case you didn’t place your bet before the match started, you
                can always bet on it while it’s being played live. This is
                called live or in-game betting. It certainly provides a
                different kind of thrill compared to pre-match betting because
                you place your bets while a match is on. Be careful while
                placing such bets because odds can change significantly as a
                match progresses.
              </p>
            </div>
            <div class="col col-lg">
              <img
                src="../../assets/images/in/OUTRIGHT.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
              >
                OUTRIGHT BETTING
              </div>
              <p style="text-align: center; padding: 1em">
                This means betting on the outright winner of a tournament. For
                example, betting on the outright winner of the next FIFA World
                Cup before it starts or betting on the winner of the next
                Australian Open before the first match is played. Typically,
                such bets are placed before a tournament begins. That said, you
                can also place an outright bet after a tournament starts, but
                the betting odds would have most likely changed by then.
              </p>
            </div>
          </div>
          <h2>Place live bets on your favourite sports</h2>
          <div class="row mt-30 mb-30">
            <div class="bg-white col col-lg">
              <img
                src="../../assets/images/in/CRICKET.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
              >
                CRICKET
              </div>
              <p style="text-align: center">
                Fun88 offers several betting opportunities to bet on India’s
                favourite sport - cricket. Be it
                <router-link to="/sports">ICC tournaments</router-link>
                or bilateral series, if a cricket match is happening, you’ll
                certainly find a betting option on our website.
              </p>
            </div>
            <div class="bg-white col col-lg">
              <img
                src="../../assets/images/in/FOOTBALL.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
              >
                FOOTBALL
              </div>
              <p style="text-align: center">
                Place bets on soccer before the match or live. Bet on the
                English Premier League (EPL), the Euro Cup, the FIFA World Cup,
                and other football tournaments exclusively at Fun88. Messi,
                Roland, or or Mbappé, punt on the players you love.
              </p>
            </div>
            <div class="bg-white col col-lg">
              <img
                src="../../assets/images/in/BASKETBALL.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
              >
                BASKETBALL
              </div>
              <p style="text-align: center">
                Keen to bet on basketball? Get the best betting odds at Fun88,
                as you bet on NBA, EuroLeague, and several other basketball
                tournaments around the world. Visit Fun88 and get all kinds of
                basketball betting options.
              </p>
            </div>
            <div class="bg-white col col-lg">
              <img
                src="../../assets/images/in/TENNIS.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
              >
                TENNIS
              </div>
              <p style="text-align: center">
                Get exclusive betting action on Fun88 when grand slams and ATP
                tournaments are on. Bet on the winner of a tournament, game
                spread, set spread, and many other betting options. Visit Fun88
                and get a lot of tennis-related predictions to help you place
                your bets.
              </p>
            </div>
          </div>
          <p>
            We also cover live Tennis, Football Sports, Premier Leagues,
            International Fixtures, and Youth Tourneys to gamble on for a
            complete sports action. With the best live gambling combinations
            available on games, match results, goal-scoring, events or
            accumulators, we’ve lined up a thrilling experience for football
            lovers. Football fans will be hooked with a comprehensive online
            sportsbook, competitive odds, and on-the-go experience on our secure
            website and mobile app, available for iOS and Android users.
          </p>

          <p>
            Soccer fans will also be in for a treat as we’ve lined up their
            favourite clubs - Newcastle United Football Club, Tottenham Hotspur
            Football Club and others -with tickets, t-shirt giveaways and
            competitions! As the official shirt sponsor and betting partner,
            we’ve enhanced the engagement levels of football fans in many ways.
          </p>

          <p>
            Likewise, we allow punters to bet on tennis and basketball
            tournaments as well. At Fun88, you can bet on Wimbledon matches,
            French Open matches, and other tennis competitions. We allow you to
            bet on Wimbledon qualifiers as well. The idea is to give tennis
            lovers as much thrill of betting as possible. Similarly, we allow
            you to bet on top basketball leagues as well.
          </p>

          <p>
            Fun88 has also started offering an online <b>lottery</b>. We’ve
            partnered with ‘<router-link to="/sports">Saba Sports</router-link>’
            for this initiative.
          </p>

          <h2>What sort of bets can you place with FUN88</h2>

          <p>
            At Fun88, you can place various kinds of pre-match, live, and
            outright bets. Be it any sport, you’d never face a lack of options
            to wager on our platform. Depending upon which aspect of a match you
            like the most, you can place your bets on Fun88. So, what kinds of
            bets can you place with Fun88?
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Match winner or moneyline bet –</b
            >
            A Moneyline bet is the most basic form of wager. It simply means
            betting on which player or team will win a game. Suppose you are an
            ardent tennis fan, who has seen Rafael Nadal and Novak Djokovic grow
            as players over the years, you would certainly like to place a bet
            if these two guys are playing against each other. All you have to do
            is, visit the tennis section of Fun88’s website, and you’ll be able
            to place a bet on the eventual winner of a match. Similarly, if you
            are a cricket fan, you can bet on which team will win an upcoming
            match.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px">Top player – </b> This
            betting option is usually available in the case of team sports, like
            cricket, football, baseball, etc. In a cricket match, you can place
            bets on ‘Top Batsman’ (who will score the maximum runs) and ‘Top
            Bowlers’ (who will take the maximum wickets). Bring all your sports
            knowledge and acumen to the table because this option lets you bet
            on who is most likely to emerge as the top player of a match. For
            example, if France and Argentina are playing a soccer match, you’ll
            be able to place a bet on whether Messi will score the maximum
            number of goals or Mbappé.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >The margin of victory or point spread bet –</b
            >
            Punters can also place live bets on the margin of victory when a
            game is on. For example, if Federer and Nadal are playing against
            each other, you can bet whether Nadal will win 3 sets to 2 or
            Federer will win 3 sets to 1. Likewise, if England and France are
            facing each other in football, you can punt on whether the margin of
            victory will be 1 goal, 2 goals, or more. It’s also called a point
            spread bet in which the better player or team is favoured by giving
            a specific number of points, which is a function of their ability to
            win a game.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px">Over/Under bets – </b>
            Over/under bets are typically placed on a certain aspect of a game,
            which doesn’t have much to do with winning or losing. For example,
            betting in a soccer match on whether both the teams put together
            will score five goals or less. That’s an over/under bet. As opposed
            to analyzing the likely winner of the game, you’ve to focus on the
            goal-scoring ability of the two teams. Similarly, in an ODI cricket
            match, betting on whether two teams will make a combined score of
            600 runs or not is an over/under bet.
          </p>

          <p>
            Apart from it, you can find many other betting options for cricket,
            football, tennis, baseball, volleyball, and kabaddi on Fun88’s
            website. If you’re a cricket buff, you can even place a bet on how
            many runs will be scored off the next delivery, how the batsman in
            the middle will get out, who the next player to lose his wicket will
            be, etc.
          </p>

          <p>
            For some of these options, you can place a bet before or after a
            match starts. For example, you don’t have to wait till the match
            starts to punt on the eventual match winner. At the same time, if
            you couldn’t place your bet before the match began, fret not because
            you can place a bet on this option even after the match has started.
          </p>

          <p>
            There would always be some betting options for which it’s better to
            let a match start before you place your bet. It varies from
            situation to situation. So, analyse a game well before starting to
            punt on it.
          </p>

          <h2>Win big with the most exciting online betting sites in india</h2>

          <p>
            Fun88 offers some of the
            <router-link to="/promo"
              >most exciting bonuses, prizes, lucky draws, and
              jackpots</router-link
            >, which make you win really big. Whether you’re a new sports bettor
            or a seasoned punter, we have amazing offers for you.
          </p>

          <p>
            Also enjoy India’s most popular contact sport tournament, Pro
            Kabaddi League and Global Kabaddi Games with an edge! You can place
            live bets on kabaddi match statistics, game-winners, players and
            more!
          </p>

          <p>
            Our entire focus on sports gambling is backed by the best technology
            to draw match analysis, competitive odds, liaise with well-known
            partners and give our fans the best online betting games. All of
            this on the go with our mobile betting app and reliable transaction
            process.
          </p>

          <button class="button" style="vertical-align: middle">
            <router-link to="/promo" style="color: #00a6ff"
              >CLICK FOR MORE PROMOTION</router-link
            >
          </button>

          <h2 class="mt-10">Most popular sports events to bet on</h2>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Indian Premier League (IPL) -
            </b>
            The most eagerly awaited T20 cricket tournament by Indian fans, the
            IPL provides tons of betting options to punters every year, as
            several teams comprising some of the best cricketers in the world
            contest for the coveted IPL trophy.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Pakistan Super League (PSL) -
            </b>
            PSL is, without doubt, an exciting T20 tournament, which brings a
            number of fine Pakistani players and cricketers from around the
            world to compete with each other. The matches are thrilling and the
            bettors have ample opportunity to make a killing.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Caribbean Premier League (CPL) -
            </b>
            A T20 competition in the Caribbean is bound to be exciting. More so,
            when it features some of the best West Indian players in this format
            of the game. Watch out for CPL betting odds at Fun88 to make the
            most of it.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px">Pro Kabaddi League - </b>
            This professional level kabaddi league is hugely popular in India.
            And, why that wouldn’t be the case, as it focuses on “Kabaddi,” our
            very own Indian sport. Bettors from all over India, whether rural or
            urban areas, love wagering on “Pro Kabaddi League.”
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px">Wimbledon - </b> The most
            prestigious grand slam is also the most awaited by those who love
            punting on tennis. Wimbledon has given us a number of nerve-racking
            matches over the years. Fun88 offers the best tennis odds to help
            you win big by betting on Wimbledon matches.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px">The US Open - </b>
            Talking of exciting grand slams, the US Open is right up there, as
            it offers a number of close matches every year. Moreover, it’s the
            only grand slam, which hasn’t been dominated by a particular player.
            So, it’s most exciting to guess as to who will win it next.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >English Premier League (EPL) -
            </b>
            EPL brings top-class football entertainment for soccer fans every
            year, as it sees the likes of Manchester United, Liverpool,
            Newcastle United, Tottenham Hotspur, Arsenal, Chelsea, and many
            other clubs competing with each other.
          </p>

          <div class="mt-50">
            <h4>Betb2b-the widest sportsbook on the betting market!</h4>

            <p>
              Want to bet on the widest range of sports with tons of betting
              options and superb features? That’s what BetB2B [1XBET] Sportsbook
              brings for you. With 182+ sports lines, 25k+ events, and 16k+ live
              events, 1XBET is the widest sportsbook you can ever find.
            </p>
          </div>
          <div class="mt-50">
            <h2>
              Why is fun88 one of the best online sports betting sites in india?
            </h2>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Regular updates on ux design for the best online betting
                experience –
              </b>
              Fun88 provides a seamless and user-friendly experience for punters
              on its website. With an intuitive and creative design, it’s
              genuinely easy to navigate to different parts of the website to
              place sports bets and participate in contests.
            </p>
          </div>
          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Bet with indian rupees (INR) –
            </b>
            At Fun88’s website, you can place your bets in your local currency,
            INR. You don’t have to convert it into a foreign currency.
            Transferring funds in rupees is as hassle-free on our platform as it
            can get.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Convenient payment methods –
            </b>
            Fun88 has some of the most convenient payment methods, which make
            fund deposit and withdrawal a breeze. These include Netbanking, UPI,
            G Pay, VISA, MasterCard, Ecopayz, Cryptocurrency, Astropay, and
            Sticpay. Having such well-recognized modes of payment assures
            punters that their money is in safe hands.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >Most competitive betting odds –
            </b>
            Fun88 is deeply committed to offering the most transparent and fair
            platform to sports bettors. That being the reason, we keep our
            margins low to offer the most competitive betting odds to punters
            willing to bet on sports. You can compare our betting odds with
            those offered by other platforms, and you’ll realise that we offer
            you some of the most fair deals. As a result, your probability of
            winning is really high at Fun88.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px"
              >24/7 customer support –
            </b>
            If you have any issues and concerns, you can always reach out to our
            customer support team
            <a href="#" target="_blank">here</a>. The customer care executives
            are available 24/7 to help bettors with any problems they face. You
            can send a Whatsapp message, call, or send an email, and our
            executives will get back to you asap.
          </p>

          <p>
            <b style="color: #00a6ff; font-size: 20px">Betting exchange – </b>
            Fun88’s
            <a href="#" target="_blank">betting exchange</a>
            enables punters to offer direct bets to each other. A betting
            exchange is specifically for a situation when you want to bet on a
            specific option, but you can’t find it on Fun88’s site. Thanks to
            the betting exchange, you can offer a bet on this option to other
            punters. As punters directly offer bets to each other, commissions
            tend to be much lower on the betting exchange.
          </p>
          <div class="mt-50">
            <h3>Bet on india’s most popular sports</h3>
            <p class="mt-10">
              <b style="color: #00a6ff; font-size: 20px">Kabaddi betting</b>
              <br />
              Also, enjoy India’s most popular contact sports tournament, Pro
              Kabaddi League and Global Kabaddi Games with an edge! You can
              place live bets on kabaddi match statistics, game-winners, players
              and more!
            </p>
            <p>
              We’re backed by the best technology to draw match analysis,
              competitive odds, reliable transaction processes, and liaising
              with well-known partners to offer seamless sports gambling and
              online betting games experience. Enjoy the thrilling world of
              online betting games on the desktop version and Fun88 mobile
              betting app.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px">IPL cricket betting</b>
              <br />
              Get a range of betting options for the Indian Premier League (IPL)
              every year on Fun88. We understand that the IPL is the biggest
              cricket bonanza for Indian fans. Therefore, at Fun88’s platform,
              they can punt on all possible aspects of the IPL. Right from the
              eventual IPL winner or orange and purple cap winners to likely
              coin-toss winner or number of runs in a particular over, you can
              get all sorts of betting opportunities on Fun88.
            </p>

            <p>
              Visit our website day after day to find the best possible betting
              option for yourself. And in case you don’t find the betting option
              that you want, you can offer your own bet to other players using
              the betting exchange. Besides, you can win amazing prizes in a
              lucky draw like BMW – X7, Suzuki Hayabusa bike, and iPhone 13 Pro
              by betting on IPL on Fun88.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px">Football betting</b>
              <br />
              Get moneyline bets, point spread bets, and over/under bets for
              soccer matches at Fun88. With a wide variety of football leagues
              like the English Premier League, The Champions League, and
              tournaments like the Euro Cup and the FIFA World Cup covered,
              you’ll get a lot of soccer betting options on our platform.
              Moreover, we partner with Newcastle United Football Club,
              Tottenham Hotspur Football Club and many others for ticket-sales
              and t-shirt giveaways, so that keeps football punters glued to our
              platform, as there are countless opportunities to show love for
              the sport and also bet on the matches.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px">Basketball betting</b>
              <br />
              Betting on basketball is fun, as it offers a lot of intensity and
              action with every shot. At Fun88, you can bet on several kinds of
              basketball matches and leagues. We offer you the most competitive
              betting odds, a state-of-art betting interface, fast payments, and
              superb prizes and offers. Visit Fun88 and bet on your favourite
              basketball team, like the Los Angeles Lakers, Toronto Raptors,
              etc. You can bet on the likely winner of a game, margin of
              victory, and several other aspects.
            </p>
          </div>
          <div class="mt-50">
            <h3>What should you look for in a sports betting site?</h3>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Check whether a betting site allows Indian bettors or not -
              </b>
              The first thing that you should check is whether Indian bettors
              are allowed on a certain platform or not. A lot of betting sites
              allow players only from a certain geography. If a platform doesn’t
              allow Indian bettors, then you can’t place bets on it.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Variety of sports offered for betting -
              </b>
              Ideally speaking, the more sports a betting platform allows you to
              bet on, the better. You won’t like to switch from one platform to
              another just because the sports you want to bet on aren’t there.
              So, you should check this aspect as well.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Range of deposit and withdrawal methods -
              </b>
              Often punters tend to have different preferences when it comes to
              deposit and withdrawal methods. Therefore, you should always
              ensure that a betting platform offers an adequate range of such
              methods. At Fun88, we have payment options like Netbanking, UPI,
              GPay, VISA, Sticpay, Mastercard, Astropay, and many more.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Mobile-friendly site -
              </b>
              Given that most punters go to a platform’s site to place their
              bets, it’s best if a platform has a mobile-friendly site.
              Conversely, if its site is not optimized for mobile, then it won’t
              be fun to bet on it.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Safe and secure site -
              </b>
              While having a mobile-friendly site is advisable, a platform
              should also ensure that it adheres to high standards when it comes
              to the safety and security of its site by using data encryption
              and other practices.
            </p>

            <p>
              <b style="color: #00a6ff; font-size: 20px"
                >Bonuses and promotions -
              </b>
              Last but not least, do check what kinds of bonuses and promotions
              are offered by a betting platform. Typically, different offers are
              aimed at new and already registered players. Also, read the terms
              and conditions for promotions in detail.
            </p>
          </div>
          <div class="mt-50">
            <div class="row">
              <div class="col col-lg">
                <h3>Download the FUN88 sports betting app for free!</h3>
                <p>
                  <b>Sports betting</b> in India is now as easy as 1-2-3 with
                  Fun88 India’s online betting app!
                </p>
                <p>
                  Choose from over multiple categories of live Cricket, Tennis,
                  Football to exciting slots, crazy jackpots, thrilling poker
                  and a live casino with over 200+ games and tables. Fun88 App
                  India is an exciting companion as you watch live sporting
                  action or are looking to profit from your gaming passion. Now
                  you can bet-on-the-move, with our power-packed, fast and
                  reliable, gambling App.
                </p>
              </div>
              <div class="col col-lg mt-10">
                <p style="text-align: center; font-size: 20px">
                  To download, scan the QR code given below from your mobile or
                  open the
                  <a :href="downloadApp" target="_blank" style="color: #00a6ff"
                    >app download link</a
                  >.
                </p>
                <div class="row mt-10">
                  <div class="col col-lg">
                    <div class="qrcode" id="qrcode"></div>
                  </div>
                  <div class="col col-lg">
                    <p
                      style="
                        margin: 0;
                        padding: 1em 0 0;
                        font-size: 28px;
                        font-weight: bold;
                        text-align: center;
                      "
                    >
                      FUN88 Main App
                    </p>
                    <p
                      style="
                        margin: 0;
                        padding: 0;
                        font-size: 22px;
                        font-weight: 100;
                        text-align: center;
                      "
                    >
                      Download the app now
                    </p>
                    <p
                      style="
                        margin-bottom: 2em;
                        padding: 0;
                        color: #00a6ff;
                        font-size: 20px;
                        text-align: center;
                      "
                    >
                      <a
                        :href="downloadApp"
                        target="_blank"
                        style="color: #00a6ff"
                        >{{ downloadApp }}</a
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-50">
            <h3>Deposit methods of sports betting sites</h3>
            <p>
              As you get geared to start sports betting with Fun88, the first
              thing to do is signing up for an account and making a deposit. The
              next steps are:
            </p>
            <p>
              - Visit the cashier at Fun88 betting site <br />
              - Choose the deposit amount and also claim any bonus offer you may
              wish to use <br />
              - Choose your selected banking method and fill in all the details
              <br />
              - Click confirm and start sports betting!
            </p>
          </div>

          <div class="row mt-20">
            <div class="col col-lg bg-white">
              <img
                src="../../assets/images/in/CARDS.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
                class="mb-10"
              >
                CREDIT /DEBIT CARDS
              </div>
              <p style="text-align: center">
                Credit/Debit cards are restricted as banking options at betting
                sites due to legislative restrictions in some regions. But in
                most parts of the world, credit cards such as Visa or MasterCard
                are used for funding your online betting account.
              </p>
            </div>
            <div class="col col-lg bg-white">
              <img
                src="../../assets/images/in/E-WALLETS.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
                class="mb-10"
              >
                E-WALLETS
              </div>
              <p style="text-align: center">
                Some of the most popular choices of payment on Fun88 EcoPayz and
                Google Pay for hassle free digital transactions.
              </p>
            </div>
            <div class="col col-lg bg-white">
              <img
                src="../../assets/images/in/BANKING.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
                class="mb-10"
              >
                NET BANKING
              </div>
              <p style="text-align: center">
                Netbanking or online transfer is a secure way to do banking in
                the comfort of your home or office anytime, anywhere.
              </p>
            </div>
            <div class="col col-lg bg-white">
              <img
                src="../../assets/images/in/BANKTRANSFER.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
                class="mb-10"
              >
                BANK TRANSFER
              </div>
              <p style="text-align: center">
                Bank transfer (or wire transfer) allows users to transfer money
                to a Fun88 bank account around the world. A unique reference
                number is shared with the details of the bank account to make
                payment.
              </p>
            </div>
            <div class="col col-lg bg-white">
              <img
                src="../../assets/images/in/UPI.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
                class="mb-10"
              >
                UPI
              </div>
              <p style="text-align: center">
                Unified Payments Interface (UPI) is also a popular payment
                system at Fun88. Just enter a UPI-PIN and your payment will be
                complete.
              </p>
            </div>
            <div class="col col-lg bg-white">
              <img
                src="../../assets/images/in/CRYPTO.png"
                style="display: block; margin: 2em auto; width: 90px"
              />
              <div
                style="
                  text-align: center;
                  font-size: 20px;
                  font-weight: bold;
                  color: #000;
                "
                class="mb-10"
              >
                CRYPTO CURRENCY
              </div>
              <p style="text-align: center">
                You can place crypto bets on sporting events, or you can use
                your cryptocurrency to play your favourite online casino games
                on Fun88.
              </p>
            </div>
          </div>
          <div class="mt-10">&nbsp;</div>
          <div class="mt-50">
            <h3>Get started with sports betting on FUN88!</h3>
            <div class="row mt-20 ipl-youtube-row">
              <div class="column">
                <iframe
                  class="ipl-youtube"
                  src="https://www.youtube.com/embed/0TXYE0NQ6D0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
              <div class="column">
                <iframe
                  class="ipl-youtube"
                  src="https://www.youtube.com/embed/PyCBHYPC23Q"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
              <div class="column">
                <iframe
                  class="ipl-youtube"
                  src="https://www.youtube.com/embed/5DES8pdnB1Y"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
              <div class="column">
                <iframe
                  class="ipl-youtube"
                  src="https://www.youtube.com/embed/nyZPdWonlPw"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>
            <p>
              Watch the video tutorials on how to bet on Fun88, how to sign-up,
              how to deposit money, how to withdraw money, and more for
              hassle-free <b>sports betting</b>.
            </p>
          </div>
          <h3 class="mb-50">
            <router-link
              to="/mobile-app"
              target="blank"
              style="
                color: #00a6ff;
                display: inline-block;
                margin-bottom: 12px;
                text-decoration: none;
              "
              >FAQs about online sports betting</router-link
            >
          </h3>
          <div class="faq mt-20">
            <details open="">
              <summary>Is it safe to bet on Fun88?</summary>
              <div class="faq__content">
                <p>
                  Remain assured that Fun88 is one of the safest sports betting
                  platforms in the world. Moreover, it is certified by E
                  Gambling Montenegro, a reputed solutions provider to gaming
                  operators to help them acquire licenses. Such licensing shows
                  that Fun88 lives up to the highest standards of safety and
                  security in gaming operations.
                </p>
              </div>
            </details>
            <details>
              <summary>How to register/sign up on Fun88?</summary>
              <div class="faq__content">
                <p>
                  Registering on Fun88 is super easy. Just click on this link -
                  <router-link to="/author?type=1"> register </router-link>
                  - and sign up. Or, watch the video below to go through a
                  step-by-step description of the registration process.
                </p>
              </div>
            </details>
            <details>
              <summary>Do I need to be a sports betting expert?</summary>
              <div class="faq__content">
                <p>
                  No, you don’t have to be a sports betting expert to wager
                  successfully. That said, you need to have in-depth knowledge
                  of the sport you’re betting on. And, you need to know the
                  basics of betting, like how to read betting odds and how odds
                  fluctuate from time to time. In a way, sports betting is more
                  about sports than about betting. As far as betting is
                  concerned, you need to have a firm grip on its basics. But for
                  sports, the more knowledge you have the better.
                </p>
              </div>
            </details>
            <details>
              <summary>How do I know how much to bet?</summary>
              <div class="faq__content">
                <p>
                  The fundamental rule of any financial transaction is, know the
                  downside. In simple words, you should bet only if you know
                  that you can bear the loss. If you can’t bear the loss of
                  losing a bet, you shouldn't place it.
                </p>
              </div>
            </details>
            <details>
              <summary>Which online betting platform should I go for?</summary>
              <div class="faq__content">
                <p>
                  The first thing that you should check is whether a betting
                  platform is trustworthy or not. Do read reviews online and ask
                  your friends if they are into sports betting. Also, check if a
                  platform has a license from a reputed betting institution.
                  Then, compare the betting odds across platforms. The
                  commissions of a platform are built into the betting odds.
                  Another factor to consider is the ease of fund deposit and
                  withdrawal.
                </p>
              </div>
            </details>
            <details>
              <summary>Do betting odds change?</summary>
              <div class="faq__content">
                <p>
                  Yes, betting odds do change based on the expectations of
                  bettors, playing conditions, forecast of a match’s result, and
                  many other factors. Two players may end up betting on the same
                  option but with different odds.
                </p>
              </div>
            </details>
            <details>
              <summary>Should I place a single bet or multiple bets?</summary>
              <div class="faq__content">
                <p>
                  You must have heard this adage - “Don’t put all your eggs in
                  one basket.‿ Well, the same is the case with betting. If you
                  place only one bet, your chances of winning or losing depend
                  only on one bet. However, if you place more than one bet, then
                  you still have a chance of winning even if a particular bet
                  doesn’t go your way.
                </p>
              </div>
            </details>
            <details>
              <summary>How do I select which option to bet on?</summary>
              <div class="faq__content">
                <p>
                  You should select only that betting option of which you
                  understand all the possible outcomes. For example, if you're
                  betting on how a certain batsman will get out, you need to
                  know his specific strengths, weaknesses, the playing
                  conditions, and all the manners in which he can get out,
                  bowled, LBW, caught, etc.
                </p>
              </div>
            </details>
            <details>
              <summary>
                Is it a good idea to pay for sports betting tips?
              </summary>
              <div class="faq__content">
                <p>
                  Often, new punters fall prey to the idea of paying for sports
                  betting tips. Typically, new bettors aren’t very sure of their
                  own betting skill, so they think it’s better to pay for a tip.
                  We’d advise you not to do it. You never know how genuine the
                  tip provider is. Besides, on Fun88’s website, we regularly
                  share sports betting tips free of cost for all kinds of
                  bettors. So, just visit our website, go through the blog
                  section, and start your betting journey.
                </p>
              </div>
            </details>
            <details>
              <summary>
                How are online sports betting better than traditional betting?
              </summary>
              <div class="faq__content">
                <p>
                  There are numerous advantages of online sports betting. First
                  of all, there are so many sports betting platforms online that
                  the market is not dominated by a few players, as can be the
                  case with traditional betting. Second, platforms like Fun88
                  adhere to the highest possible standards of transparency,
                  whether it’s about offering betting odds or deposit and
                  withdrawal of funds.
                </p>
              </div>
            </details>
          </div>
        </div>
        <div @click="moreChange" class="read-more">READ MORE</div>
      </div>
    </div>
  </div>
</template>
<script>
import { _getToken } from "../../core/comp/common";
import Banner from "../../core/components/Banner.vue";
import { _createQRcode, _downloadApp } from "../../core/utils/utils";
import { mapGetters } from "vuex";

export default {
  components: {
    Banner,
  },
  data() {
    return {
      curId: "",
      curItem: {},
      newList: [], // 分割之后的新数组
      moreShow: true,
      downloadApp: "",
    };
  },
  computed: {
    lists() {
      // 二级导航
      return this.gameMenu.arr_ty || [];
    },
    ...mapGetters([
      "gameMenu", // 游戏分类
      "systemImgHost",
    ]),
  },
  mounted() {
    this.splitList();
    this.qrcodeChange();
    this.curItem = this.lists[0];
    this.curId = this.lists[0].id;
  },
  methods: {
    qrcodeChange() {
      this.downloadApp = _downloadApp();
      _createQRcode({
        id: "qrcode",
        content: this.downloadApp,
        width: 158,
        height: 158,
      });
    },
    _getToken,
    switchTab(item) {
      this.curItem = item;
      this.curId = item.id;
    },
    // 数组切割 列表 每组几个
    splitList() {
      for (let i = 0; i < this.lists.length; i += 5) {
        this.newList.push(this.lists.slice(i, i + 5));
      }
    },
    moreChange() {
      this.moreShow = !this.moreShow;
    },
  },
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  position: relative;
  padding: 0 0;
  margin: 0 0;
  background: #fff;
  .mod-sports {
    min-height: 804px;
    overflow: hidden;
  }
  .mod-banner {
    width: 100%;
    height: 460px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    z-index: 5;
  }
  .slide-wrap {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .clearfix {
    width: 100%;
    display: inline-block;
  }
  ul {
    list-style: none;
  }
  li {
    width: 25%;
    height: 191px;
    margin-top: 2%;
    padding: 0px 20px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    list-style-type: none;
    box-sizing: border-box;
    .btn {
      background: url(../../assets/images/in/play_now_btn.png) no-repeat center
        center / contain;
      display: block;
      height: 3.8vw;
      width: 9vw;
      text-align: center;
      position: absolute;
      bottom: 6%;
      left: 50%;
      transform: translateX(-50%);
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .sport-platform {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .sport-text {
    margin-top: 30px;
    padding: 30px 0;
    background: #fff;
  }
  .page-text {
    max-width: 1400px;
    margin: 30px auto 0 auto;
    a {
      color: #00a6ff;
      text-decoration: underline;
      font-weight: bold;
    }
    .layout {
      max-width: 1200px;
      width: 100%;
      overflow: hidden;
      margin: 0 auto;
    }
    p {
      line-height: 1.5;
      margin: 0 0;
      padding: 0 0 15px 0;
      font-size: 16px;
      color: #404040;
    }
    h1 {
      line-height: 54px;
    }
    .layout-height {
      height: 300px;
    }
  }
  .read-more {
    width: 241px;
    height: 41px;
    margin: 30px auto 0;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #00a6ff;
    line-height: 41px;
    text-align: center;
    color: #00a6ff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
}
.page-text h1,
.page-text h2,
.page-text h3,
.page-text h4,
.page-text h5,
.page-text h6 {
  padding: 0 0;
  margin: 0 0;
  font-weight: 600;
  color: #00a6ff;
}

.page-text,
.agency-content {
  font-size: 16px;
}

.page-text p,
.agency-content p {
  padding-bottom: 15px;
  font-size: 16px;
  color: #404040;
}

.page-text b {
  color: #00a6ff;
}

.page-text h1 {
  margin-bottom: 10px;
}

.page-text h2 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.page-text h3 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.page-text h4 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.page-text h5 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.ipl-youtube-row {
  justify-content: space-between;
}

.ipl-youtube-row .column {
  flex-basis: 48.5%;
}

.text-decoration {
  text-decoration: none;
}

.bettingInfo h1 {
  font-size: 20pt;
}

.bettingInfo h2 {
  font-size: 20pt;
}

.bettingInfo h3 {
  font-size: 20pt;
}

.bettingInfo h4 {
  font-size: 20pt;
}

.page-text .ipl-youtube {
  width: 100%;
  height: 315px;
  margin-bottom: 6%;
  border: 10px solid black;
  max-width: 100%;
  box-sizing: border-box;
}

.bettingInfo .ipl-youtube {
  width: 100%;
  height: 196px;
  border: 0;
  margin: 10px 0;
}

.bettingInfo .button {
  background-color: #fff;
  box-shadow: 0px 5px 10px #00000029;
  border: 4px solid #00a6ff;
  border-radius: 91px;
  color: #00a6ff;
  padding: 16px 10px;
  text-align: center;
  font-size: 16pt;
  font-weight: bold;
  margin: 1em auto;
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: block;
  width: 100%;
}

.page-text .button {
  background-color: #fff;
  box-shadow: 0px 5px 10px #00000029;
  border: 4px solid #00a6ff;
  border-radius: 91px;
  color: #00a6ff;
  padding: 16px 32px;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
  margin: 1em auto 1.5em;
  opacity: 1;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  display: block;
  width: 50%;
}

.page-text .button a {
  text-decoration: none;
}

.button:hover {
  opacity: 0.9;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
/* FAQ */

.faq details summary {
  color: #00a6ff;
  padding: 18px 7px;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: 1px solid #e3e3e3;
  position: relative;
  list-style: none;
  font-size: 20px;
  font-weight: 500;
}

.faq details summary:last-child {
  border-bottom: 1px solid #e3e3e3;
}

.faq details summary::after {
  position: absolute;
  background: url(../../assets/images/in/right.png) no-repeat scroll;
  right: 20px;
  width: 26px;
  height: 26px;
  content: " ";
}

.faq details summary::marker {
  display: none;
}

.faq details[open] {
  background-color: #f8f8f8;
}

.faq details[open] summary ~ * {
  -webkit-animation: sweep 0.5s ease-in-out;
  animation: sweep 0.5s ease-in-out;
}

.faq details[open] summary {
  background-color: #f8f8f8;
  padding: 18px 15px;
  border-bottom: 1px solid #f8f8f8;
}

.faq details[open] summary::after {
  position: absolute;
  background: url(../../assets/images/in/down.png) no-repeat scroll;
  right: 20px;
  width: 26px;
  height: 26px;
  content: " ";
}

.faq details[open] .faq-content {
  border-top: 0px solid #e3e3e3;
}

.page-text .faq__content {
  border-left: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  border-top: 0px solid #e3e3e3;
  padding: 0 40px 10px 15px;
}

.page-text .faq__content p {
  margin: 0;
  color: #707071;
}

.faq .button {
  background-color: #00a6ff;
  color: #fff;
  padding: 16px 32px;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin: 1em auto;
  cursor: pointer;
  display: inline-block;
  border: 1px;
  text-decoration: none;
  border-radius: 0;
}
/* FAQ */

.bg-white {
  box-shadow: 0px 0px 15px #00a6ff38;
  margin: 1em;
  background-color: white;
  padding: 12px;
}

.col-lg {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.row [class*="col"] {
  position: relative;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.types-of-sports-row {
  justify-content: space-between;
}

.types-of-sports-row .col {
  flex-basis: 28%;
  flex-grow: 0;
}

@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }
  .bg-white {
    margin: 1em 0;
  }
  .page-text .button {
    width: 100%;
    padding: 12px 24px;
    text-align: center;
    font-size: 18px;
  }
  .mb-20 {
    margin-bottom: 15px;
  }
  .mt-20 {
    margin-top: 15px;
  }
  .mb-30 {
    margin-bottom: 20px;
  }
  .mt-30 {
    margin-top: 20px;
  }
  .mb-40 {
    margin-bottom: 25px;
  }
  .mt-40 {
    margin-top: 25px;
  }
  .mb-50 {
    margin-bottom: 30px;
  }
  .mt-50 {
    margin-top: 30px;
  }
  .faq details summary {
    padding: 18px 30px 18px 7px;
  }
  .faq details summary::after,
  .faq details[open] summary::after {
    right: 10px;
    width: 20px;
    height: 20px;
    background-size: cover;
    top: 50%;
    transform: translateY(-50%);
  }
  .page-text .faq__content {
    padding: 0 15px 10px;
  }
}

@media (min-width: 801px) {
  .page-text h1,
  .page-text h2,
  .page-text h3,
  .page-text h4,
  .page-text h5,
  .page-text h6 {
    font-size: 36px;
  }
}
.qrcode {
  width: 158px;
  height: 158px;
  margin: 20px auto;
}
</style>